<template>
  <div v-if="isShow">
    <Loading/>
  </div>
  <div class="page-banner" :style="{ 'background-image': 'url(' + banner + ')' }">
    <div class="overlay">
      <div class="trapezoidal-block">
        <h1>{{ $t("inquiry") }}</h1>
        <div class="small-nav">
          <router-link to="/">
            {{ $t("home") }}
          </router-link>
          <span>丨</span>
          <router-link to="/inquiry">
            {{ $t("inquiry") }}
          </router-link>
        </div>
      </div>
    </div>
  </div>

  <div class="middle common-padding-100">
      <div class="c-middle">
        <h1>{{ $t("inquiryForm.inquiryForm01") }}</h1>

        <div class="part-1 common-part">
          <h3>{{ $t("inquiryForm.inquiryForm02") }}<span></span></h3>
          <div class="inquiry-item">
            <p class="field"><span>*</span>{{ $t("inquiryForm.inquiryForm02") }}：</p>
            <div class="write">
              <el-input v-model="name" resize="none" autosize type="textarea" />
              <p class="tips">{{ $t("inquiryForm.inquiryForm03") }}</p>
            </div>
          </div>
        </div>

        <div class="part-2 common-part">
          <h3>{{ $t("inquiryForm.inquiryForm04") }}<span>{{ $t("inquiryForm.inquiryForm03") }}</span></h3>
          <div class="inquiry-item">
            <p class="field"><span>*</span>{{ $t("inquiryForm.inquiryForm05") }}:</p>
            <div class="write">
              <el-input  v-model="message"  :autosize="{ minRows: 6 }"  resize="none"  type="textarea" />
            </div>
          </div>
          <div class="inquiry-item">
            <p class="field">{{ $t("inquiryForm.inquiryForm06") }}:</p>
            <div class="write">
              <el-input v-model="area" resize="none" autosize type="textarea" />
            </div>
          </div>
          <div class="inquiry-item">
            <p class="field">{{ $t("inquiryForm.inquiryForm07") }}:</p>
            <div class="write">
              <el-input v-model="quantity" resize="none" autosize type="textarea" />
            </div>
          </div>
          <div class="inquiry-item">
            <p class="field">{{ $t("inquiryForm.inquiryForm08") }}:</p>
            <div class="write">
              <el-checkbox-group v-model="checkList" class="questions-list">
                <el-checkbox label="Product Brochure Request" value="1" />
                <el-checkbox label="Sample Request" value="2" />
                <el-checkbox label="Minimum Order" value="3" />
                <el-checkbox label="Delivery Lead Time" value="4" />
                <el-checkbox label="Minimum Order Price" value="5" />
                <el-checkbox label="Payment Condition" value="6" />
              </el-checkbox-group>
            </div>
          </div>
          <div class="inquiry-item">
            <p class="field">{{ $t("inquiryForm.inquiryForm09") }}:</p>
            <div class="write">
              <input type="file" ref="inquiryFileInput" @change="handleImageChange($event, 'inquiry')" style="display: none" />
              <el-button @click="upload('inquiry')">
                  <span style="margin-right: 10px">
                    <i class="iconfont icon-shangchuan"></i>
                  </span>{{ $t("inquiryForm.inquiryForm10") }}
              </el-button>
              <div class="upload-tips">
                {{ $t("inquiryForm.inquiryForm11") }}
              </div>
              <div class="view-img" v-if="inquiryImage">
                <img :src="inquiryImage">
              </div>
            </div>
          </div>
        </div>

        <div class="part-3 common-part">
          <h3>{{ $t("inquiryForm.inquiryForm12") }}</h3>
          <div class="inquiry-item">
            <p class="field"><span>*</span>{{ $t("inquiryForm.inquiryForm13") }}:</p>
            <div class="write">
              <el-input v-model="company" resize="none" autosize type="textarea" />
            </div>
          </div>
          <div class="inquiry-item">
            <p class="field"><span>*</span>{{ $t("inquiryForm.inquiryForm14") }}:</p>
            <div class="write">
              <el-input v-model="email" resize="none" autosize type="textarea" />
            </div>
          </div>
          <div class="inquiry-item">
            <p class="field"><span>*</span>{{ $t("inquiryForm.inquiryForm16") }}:</p>
            <div class="write">
              <el-input v-model="person" resize="none" autosize type="textarea" />
            </div>
          </div>
          <div class="inquiry-item">
            <p class="field">{{ $t("inquiryForm.inquiryForm17") }}:</p>
            <div class="write">
              <!-- <el-select v-model="country" :placeholder="$t('inquiryForm.inquiryForm30')">-->
              <!-- <el-option-->
              <!-- v-for="item in countryList"-->
              <!-- key="item.name"-->
              <!-- :label="item.name"-->
              <!--   :value="item.name"-->
              <!--  />-->
              <!--  </el-select>-->
              <el-input v-model="country" resize="none" autosize type="textarea" />
            </div>
          </div>
          <div class="inquiry-item">
            <p class="field">{{ $t("inquiryForm.inquiryForm18") }}:</p>
            <div class="write">
              <el-input v-model="address" resize="none" autosize type="textarea" />
            </div>
          </div>
<!--          <div class="inquiry-item">-->
<!--            <p class="field">{{ $t("inquiryForm.inquiryForm19") }}:</p>-->
<!--            <div class="write">-->
<!--              <el-input v-model="city" resize="none" autosize type="textarea" />-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="inquiry-item">-->
<!--            <p class="field">{{ $t("inquiryForm.inquiryForm20") }}:</p>-->
<!--            <div class="write">-->
<!--              <el-input v-model="zip" resize="none" autosize type="textarea" />-->
<!--            </div>-->
<!--          </div>-->
          <div class="inquiry-item">
            <p class="field"><span>*</span>{{ $t("inquiryForm.inquiryForm21") }}:</p>
            <div class="write">
              <el-input v-model="telephone" resize="none" autosize type="textarea" />
            </div>
          </div>
          <div class="inquiry-item">
            <p class="field">{{ $t("inquiryForm.inquiryForm22") }}:</p>
            <div class="write">
              <el-input v-model="fax" resize="none" autosize type="textarea" />
            </div>
          </div>
          <div class="inquiry-item">
            <p class="field">{{ $t("inquiryForm.inquiryForm23") }}:</p>
            <div class="write">
              <div style="display: flex;align-items: center">
                <span style="padding-right: 10px">http://</span>
                <el-input v-model="website" resize="none" autosize type="textarea" />
              </div>
            </div>
          </div>
          <div class="inquiry-item">
            <p class="field">{{ $t("inquiryForm.inquiryForm24") }}:</p>
            <div class="write">
              <input type="file" ref="secondaryFileInput" @change="handleImageChange($event, 'secondary')" style="display: none" />
                <el-button @click="upload('secondary')">
                  <span style="margin-right: 10px">
                    <i class="iconfont icon-shangchuan"></i>
                  </span>{{ $t("inquiryForm.inquiryForm10") }}
                </el-button>
                <div class="upload-tips">
                  {{ $t("inquiryForm.inquiryForm11") }}
                </div>
              <div class="view-img" v-if="cardImage">
                <img :src="cardImage">
              </div>
            </div>
          </div>
          <div class="inquiry-item">
            <p class="field">{{ $t("inquiryForm.inquiryForm25") }}:</p>
            <div class="write">
              <el-radio-group v-model="gender">
                <el-radio value="1" size="large">{{ $t("inquiryForm.inquiryForm31") }}</el-radio>
                <el-radio value="2" size="large">{{ $t("inquiryForm.inquiryForm32") }}</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="inquiry-item">
            <p class="field">{{ $t("inquiryForm.inquiryForm26") }}:</p>
            <div class="write">
              <el-input v-model="job" resize="none" autosize type="textarea" />
            </div>
          </div>
<!--          <div class="inquiry-item">-->
<!--            <p class="field">{{ $t("inquiryForm.inquiryForm27") }}:</p>-->
<!--            <div class="write">-->
<!--              <el-input v-model="secondEmail" resize="none" autosize type="textarea" />-->
<!--            </div>-->
<!--          </div>-->
          <div class="inquiry-item">
            <p class="field"><span>*</span>{{ $t("inquiryForm.inquiryForm28") }}:</p>
            <div class="write">
              <div class="ver-code">
                <el-input v-model="correctCode" resize="none" autosize type="textarea"/>
                <div class="img-verify">
                  <img :src="imgCode" alt="Verification Code" @click="handleDraw" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="common-btn center">
          <div @click="submitForm">
            {{ $t("inquiryForm.inquiryForm29") }}
            <span><i class="iconfont icon-youjiantou"></i></span>
          </div>
        </div>
      </div>
  </div>

  <!--提示窗-->
  <div v-if="isPopupVisible" class="popup">
    {{ popupText }}
  </div>

  <!--小加載中-->
  <div class="loading-container" v-if="sLoading">
    <div class="loading-indicator"></div>
    <div class="loading-text">{{ $t("loading") }}...</div>
  </div>


</template>

<script>
import { ref } from 'vue'
import Loading from "@/components/loading";
import {inquiry, country, uploadFiles, inquiryInfo} from "@/network/main";

export default {
  name: "Inquiry",
  components:{
    Loading
  },
  data(){
    return{
      isShow:false,
      banner:'',
      seo:'',
      fileList: null,
      name:'',
      message:'',
      area:'',
      quantity:'',
      questions:'',
      checkList:[],
      company:'',
      email:'',
      person:'',
      country:'',
      address:'',
      city:'',
      zip:'',
      telephone:'',
      fax:'',
      website:'',
      card:'',
      gender:null,
      job:'',
      secondEmail:'',
      countryList:[],
      isPopupVisible: false,
      popupText: '',
      inquiryImage: null,
      cardImage: null,
      pool: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890',
      width: 120,
      height: 40,
      imgCode: '',
      generatedCode: '',
      correctCode: '',
      sLoading: false,
    }
  },
  setup() {
    const meta = ref({
      title: "",
      keywords: "",
      description: "",
      baiduSiteVerification : ""
    });
    return {
      meta
    }
  },
  metaInfo () {
    return {
      title:"Inquiry - OSONG ELECTRIC Co. LTD",
      meta: [
        {
          name: 'description',
          content: this.seo
        }
      ],
    }
  },
  created() {
    this.getInfo();
    this.getInquiryInfo()
  },
  mounted() {
    this.handleDraw();
  },
  methods: {
    //验证码
    handleDraw() {
      this.imgCode = this.draw();
      console.log('Generated code:', this.generatedCode);
    },
    draw() {
      let code = '';
      for (let i = 0; i < 4; i++) {
        code += this.pool[Math.floor(Math.random() * this.pool.length)];
      }
      this.generatedCode = code;
      return this.renderCode(code);
    },
    renderCode(code) {
      const canvas = document.createElement('canvas');
      canvas.width = this.width;
      canvas.height = this.height;
      const ctx = canvas.getContext('2d');

      // 绘制背景
      ctx.fillStyle = '#f0f0f0';
      ctx.fillRect(0, 0, this.width, this.height);

      // 绘制验证码
      ctx.font = '30px Arial';
      ctx.fillStyle = '#333';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      for (let i = 0; i < code.length; i++) {
        const x = this.width / 5 + i * this.width / 5;
        const y = this.height / 2;
        ctx.save();
        ctx.translate(x, y);
        ctx.rotate((Math.PI / 180) * (Math.random() * 60 - 30));
        ctx.fillText(code[i], 0, 0);
        ctx.restore();
      }
      return canvas.toDataURL();
    },
    //获取banner及seo
    getInquiryInfo(){
      inquiryInfo().then(res =>{
        this.banner = res.data.data.banner;
        this.seo = res.data.data.seo;
      })
    },
    //获取国家
    getInfo() {
      country().then(res =>{
        this.countryList = res.data.data;
      })
    },
    //上传图片
    async handleImageChange(event, type) {
      this.sLoading = true;
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      try {
        const res = await uploadFiles(formData);
        if (res.data.code === 200) {
         this.sLoading = false;
          if (type === 'inquiry') {
            this.inquiryImage = res.data.data;
          } else {
            this.cardImage = res.data.data;
          }
        } else {
          this.isPopupVisible = true;
          this.popupText = res.data.message;
          setTimeout(() => {
            this.isPopupVisible = false;
          }, 2000);
        }
      } catch (err) {
        console.log(err);
      }
    },
    upload(type) {
      this.$refs[type + 'FileInput'].click();
    },
    //提交表格
    submitForm() {
      // 检查必填字段
      if (!this.name || !this.message || !this.company || !this.email || !this.person || !this.telephone) {
        this.isPopupVisible = true;
        this.popupText = this.$t('contactForm.required');
        setTimeout(() => {
          this.isPopupVisible = false;
        }, 2000);
        return;
      }
      // 验证验证码是否正确
      if (this.correctCode.toLowerCase() !== this.generatedCode.toLowerCase()) {
        this.isPopupVisible = true;
        this.popupText = this.$t('contactForm.error');
        setTimeout(() => {
          this.isPopupVisible = false;
        }, 2000);
        return;
      }

      const formData = new FormData();
      // 添加必填字段
      formData.append('name', this.name);
      formData.append('message', this.message);
      formData.append('company', this.company);
      formData.append('email', this.email);
      formData.append('contact_person', this.person);
      // formData.append('country', this.country);
      // formData.append('address', this.address);
      formData.append('telephone', this.telephone);
      formData.append('generatedCode', this.generatedCode);

      // 添加其他字段(如果有值)
      if (this.area) {
        formData.append('delivery_country_or_area', this.area);
      }
      if (this.quantity) {
        formData.append('estimated_order_quantity', this.quantity);
      }
      if (this.inquiryImage) {
        formData.append('inquiry_image', this.inquiryImage);
      }
      // if (this.city) {
      //   formData.append('city', this.city);
      // }
      // if (this.zip) {
      //   formData.append('zip_code', this.zip);
      // }
      if (this.fax) {
        formData.append('fax', this.fax);
      }
      if (this.website) {
        formData.append('website', this.website);
      }
      if (this.cardImage) {
        formData.append('business_card', this.cardImage);
      }
      if (this.gender) {
        formData.append('gender', this.gender);
      }
      if (this.job) {
        formData.append('job_title', this.job);
      }
      // if (this.secondEmail) {
      //   formData.append('second_email', this.secondEmail);
      // }

      // 将 `checkList` 数组转换成以逗号分隔的字符串
      const selectedValues = this.checkList.join(',');
      if (selectedValues) {
        formData.append('related_questions', selectedValues);
      }

      // 提交表单数据
      inquiry(formData).then((res) => {
        this.sLoading = true;
        if (res.data.code === 200) {
          this.sLoading = false
          this.isPopupVisible = true;
          this.popupText = this.$t('contactForm.success');
          setTimeout(() => {
            this.isPopupVisible = false;
            this.$router.push('/');
          }, 2000);
        } else {
          this.isPopupVisible = true;
          this.popupText = res.data.message;
          setTimeout(() => {
            this.isPopupVisible = false;
          }, 2000);
        }
      }).catch(err => {
        console.log(err);
      });
    }
  }
}
</script>

<style scoped>
@import "~@/assets/css/form.css";
</style>